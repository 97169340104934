import LandingPageQuery from '@greendistrict/sdk/LandingPage/LandingPageQuery';
import LandingPage from '@greendistrict/sdk/LandingPage/LandingPage';
import PageProps from '@greendistrict/sdk/Page/PageProps';

export const getStaticProps = PageProps({
  cms: {
    query: LandingPageQuery
  }
});

export default LandingPage;
